import React from "react"
import BodyLayout from "../components/bodyLayout"
import { Col, Row, Icon } from "antd"
import Img from "gatsby-image"
import SEO from '../components/SEO'

export default ({data}) => (
  <BodyLayout color='rgb(121, 217, 124)'>
  <SEO title={'Statiste - Guide client'} description={"Conception de site web statiques"} />
  <div>
  <Row style={{paddingTop:30}}>
  <Col xs={{span:22,offset:1}} md={{span:20,offset:2}}>
    <Row type="flex" align="middle" left="space-between">
      <Col span={8}>
      <Img fluid={data.profile.childImageSharp.fluid} />
      </Col>
      <Col span={14} offset={1}>
      <h1 id="gir" style={{border: '3px dashed'}}>Le guide de la connaissance client</h1>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour qui ?</h3>
      <p>
      <span id="gcc">Tous ceux qui veulent mieux comprendre leurs clients</span>. Affiner la segmentation actuelle ou la créer.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour quoi ?</h3>
      <p>
      Regrouper ses clients en segments permet de mieux cibler leurs attentes et de <span id="gcc">créer une relation plus pérenne</span> ou bien
      d'agir en amont d'un potentiel churn.
      </p>
      <p>
      Bien souvent, les marques concentrent plus d'effort à l'acquisition qu'à la fidélisation. Une segmentation pertinente permet <span id="gcc">d'augmenter la satisfaction client</span> et
      par conséquent la valeur vie client.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Comment ?</h3>
      <p>
      Grâces à des algorithmes de clusterings appliqués sur des variables pertinentes.
      </p>
      <p>
      Plus d'infos en téléchargeant le guide de la connaissance client en exemple.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={23} offset={0} align="left">
      <h4>Quelques questions:</h4>
      <p><Icon type="right"/>Quel est le meilleur moment et la meilleure façon de contacter tel propect/client ?</p>
      <p><Icon type="right"/>Ma base de données clients devient-elle de plus en plus opportuniste ou de plus en plus loyale ?</p>
      <p><Icon type="right"/>Etant donné un client, sera-t-il plus à même d'updgrader, downgrader, churner ?</p>
      <p><Icon type="right"/>Quelle est la probabilité qu'un client achète mon nouveau produit ?</p>
      <p><Icon type="right"/>Combien dois-je investir sur chaque segment pour rester rentable ?</p>
      </Col>
    </Row>
  </Col>
  </Row>
  </div>
  </BodyLayout>
)

export const query = graphql`
  {
    profile: file(relativePath: {eq:"img/crowd.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1000){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `
